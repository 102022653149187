










import { Component, Prop, Vue } from 'vue-property-decorator'

// components
import VKIDIcon from '@/components/VKIDIcon.vue'
@Component({
  components: {
    VKIDIcon,
  },
})
export default class VKIDProfile extends Vue {
  @Prop({ required: true })
  private userName!: string

  @Prop({ required: true })
  private id!: number
}
