


































































import { Component, Prop } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { Bind, Debounce } from 'lodash-decorators'

// components
import Dialog from '@/components/modals/Dialog.vue'
import ModalWrapper from '@/components/modals/ModalWrapper.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import ProfileModule from '@/store/modules/profile'

@Component({
  components: {
    Dialog,
    ModalWrapper,
    TextInput,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class ChangeEmailModal extends NotifyMixin {
  @Prop({ default: '' })
  private email!: string

  @Prop({ default: false })
  private hasPassword!: boolean

  @Prop({ default: false })
  private visible!: boolean

  private form = {
    password: '',
    passwordConfirmation: '',
  }
  private password = ''
  private isFocusPassword = false
  private typePassword = true
  private isFocusNewPassword = false
  private typeNewPassword = true

  @Debounce(300)
  @Bind
  private handleSubmit () {
    const form: any = this.$refs.form

    form.validate()
      .then(async (result: boolean) => {
        if (result) {
          this.hasPassword ?
            ProfileModule.updateEmail({ email: this.email, password: this.password })
              .then(() => {
                requestAnimationFrame(() => (form.reset()))
                this.notifySuccess('Почта обновлена')
                this.handleClose(false)
              })
              .catch(this.notifyError) :
            ProfileModule.updateEmailAndPassword({ ...this.form, email: this.email })
              .then(() => {
                requestAnimationFrame(() => (form.reset()))
                this.notifySuccess('Почта обновлена, пароль сохранен')
                this.handleClose(false)
              })
              .catch(this.notifyError)
        } else {
          this.notifyError('Проверьте введенные данные')
        }
      })
  }

  private handleClose(resetEmail = true) {
    this.$emit('close', resetEmail)
    requestAnimationFrame(() => ((this.$refs.form as any).reset()))
    this.password = ''
    this.form = {
      password: '',
      passwordConfirmation: '',
    }
  }
}
