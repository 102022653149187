










import { Component, Prop, Vue } from 'vue-property-decorator'

// components
import TelegramIcon from '@/components/TelegramIcon.vue'
@Component({
  components: {
    TelegramIcon,
  },
})
export default class TelegramProfile extends Vue {
  @Prop({ required: true })
  private userName!: string

  @Prop({ required: true })
  private id!: number
}
