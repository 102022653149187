














import { DropzoneFile } from 'dropzone'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import VueDropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

import NotifyMixin from '@/mixins/NotifyMixin'
import { baseURL, getToken } from '@/utils/services/config'

@Component({
  components: {
    'vue-dropzone': VueDropzone,
  },
})
export default class UploadAvatar extends Mixins(NotifyMixin) {
  @Prop({ default: 'dropzone-avatar' })
  private id!: string

  @Prop({ default: 'image/*' })
  private accept!: string

  @Prop({ default: 'Изменить' })
  private placeholder!: string

  @Prop({ default: false })
  private disabled!: boolean

  private get headers () {
    const headers: HeadersInit = {}

    const authToken = getToken()

    if (authToken) {
      headers.Authorization = `Bearer ${authToken}`
    }

    return headers
  }

  private get options () {
    return {
      acceptedFiles: this.accept,
      addRemoveLinks: true,
      autoProcessQueue: true,
      autoQueue: true,
      createImageThumbnails: false,
      headers: this.headers,
      url: `${baseURL}/upload`,
      withCredentials: true,
    }
  }

  private handleComplete (file: DropzoneFile) {
    if (file.xhr) {
      const json = JSON.parse(file.xhr.response)
      this.$emit('success', json)
    }

    (this.$refs.dz as any).removeFile(file)
  }

  private handleError (file: DropzoneFile) {
    (this.$refs.dz as any).removeFile(file)
    this.notifyError('Ошибка загрузки файла')
  }
}
