var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Dialog',{attrs:{"visible":_vm.visible},on:{"update:visible":function($event){_vm.visible=$event}}},[_c('ModalWrapper',{attrs:{"title":_vm.hasPassword ? 'Изменить пароль' : 'Создать пароль',"buttonCancelText":"Вернуться","buttonConfirmText":"Сохранить"},on:{"close":_vm.handleClose,"cancel":_vm.handleClose,"confirm":_vm.handleSubmit}},[_c('div',{staticClass:"my-8"},[_c('div',{staticClass:"text-body-1 password-message"},[_vm._v("Пароль должен содержать заглавные и строчные буквы, цифры и специальные символы и быть не менее 8 символов.")])]),_c('ValidationObserver',{ref:"form",attrs:{"tag":"div"}},[(_vm.hasPassword)?_c('ValidationProvider',{attrs:{"name":"oldPassword","rules":"required|min:8","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{class:{ 'append-icon': true, 'append-icon_active': !_vm.typeOldPassword },attrs:{"name":"oldPassword","label":"Текущий пароль","type":_vm.typeOldPassword ? 'password' : 'text',"placeholder":"Введите текущий пароль","appendIcon":!_vm.isFocusOldPassword ? undefined : _vm.typeOldPassword ? '$eyeClose' : '$eye',"invalid":errors.length > 0,"error":errors[0]},on:{"click:append":function($event){_vm.typeOldPassword = !_vm.typeOldPassword},"focus":function($event){_vm.isFocusOldPassword = true},"blur":function($event){_vm.isFocusOldPassword = false}},model:{value:(_vm.form.oldPassword),callback:function ($$v) {_vm.$set(_vm.form, "oldPassword", $$v)},expression:"form.oldPassword"}})]}}],null,false,3460414187)}):_vm._e(),_c('ValidationProvider',{staticClass:"my-8",attrs:{"name":"password","rules":"required|min:8|password:@newPasswordConfirmation","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{class:{ 'append-icon': true, 'append-icon_active': !_vm.typeNewPassword },attrs:{"name":"password","label":"Новый пароль","type":_vm.typeNewPassword ? 'password' : 'text',"placeholder":"Введите новый пароль","appendIcon":!_vm.isFocusNewPassword ? undefined : _vm.typeNewPassword ? '$eyeClose' : '$eye',"invalid":errors.length > 0,"error":errors[0]},on:{"click:append":function($event){_vm.typeNewPassword = !_vm.typeNewPassword},"focus":function($event){_vm.isFocusNewPassword = true},"blur":function($event){_vm.isFocusNewPassword = false}},model:{value:(_vm.form.newPassword),callback:function ($$v) {_vm.$set(_vm.form, "newPassword", $$v)},expression:"form.newPassword"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"newPasswordConfirmation","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{class:{ 'append-icon': true, 'append-icon_active': !_vm.typePasswordConfirmation },attrs:{"name":"newPasswordConfirmation","label":"Подтверждение пароля","type":_vm.typePasswordConfirmation ? 'password' : 'text',"placeholder":"Повторите пароль","appendIcon":!_vm.isFocusPasswordConfirmation ? undefined : _vm.typePasswordConfirmation ? '$eyeClose' : '$eye',"invalid":errors.length > 0,"error":errors[0]},on:{"click:append":function($event){_vm.typePasswordConfirmation = !_vm.typePasswordConfirmation},"enter":_vm.handleSubmit,"focus":function($event){_vm.isFocusPasswordConfirmation = true},"blur":function($event){_vm.isFocusPasswordConfirmation = false}},model:{value:(_vm.form.newPasswordConfirmation),callback:function ($$v) {_vm.$set(_vm.form, "newPasswordConfirmation", $$v)},expression:"form.newPasswordConfirmation"}})]}}])}),(!_vm.hasPassword && !_vm.hasEmail)?[_c('div',{staticClass:"my-8"},[_c('div',{staticClass:"text-body-1 password-message"},[_vm._v("Для дальнейшей возможности восстанавливать пароль введи почту.")])]),_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"name":"email","label":"Почта","placeholder":"Введите почту","invalid":errors.length > 0,"error":errors[0]},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,false,3791758617)})]:_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }