




import { MetaInfo } from 'vue-meta'
import { Component, Vue } from 'vue-property-decorator'

import ProfileInformationForm from '@/components/forms/profile/ProfileInformationForm.vue'

@Component({
  components: {
    ProfileInformationForm,
  },
})
export default class Information extends Vue {
  private metaInfo (): MetaInfo {
    return {
      title: 'Данные профиля',
    }
  }
}
