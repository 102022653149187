









































































import { Bind, Debounce } from 'lodash-decorators'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import Dialog from '@/components/modals/Dialog.vue'
import ModalWrapper from '@/components/modals/ModalWrapper.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import ProfileModule from '@/store/modules/profile'
import { UserPassword, UserSetPassword } from '@/store/types'

@Component({
  components: {
    Dialog,
    ModalWrapper,
    TextInput,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class NewPasswordFormModal extends Mixins(NotifyMixin) {
  @Prop({ default: false })
  private hasPassword!: boolean

  @Prop({ default: false })
  private hasEmail!: boolean

  @Prop({ default: false })
  private visible!: boolean

  private form: UserPassword | UserSetPassword = {
    email: undefined,
    newPassword: '',
    newPasswordConfirmation: '',
    oldPassword: '',
  }

  private isFocusOldPassword = false
  private isFocusNewPassword = false
  private isFocusPasswordConfirmation = false
  private typeOldPassword = true
  private typeNewPassword = true
  private typePasswordConfirmation = true

  @Debounce(300)
  @Bind
  private handleSubmit () {
    const form: any = this.$refs.form

    form.validate()
      .then(async (result: boolean) => {
        if (result) {
          this.hasPassword ?
            ProfileModule.updatePassword({
              newPassword: (this.form as UserPassword).newPassword,
              newPasswordConfirmation: (this.form as UserPassword).newPasswordConfirmation,
              oldPassword: (this.form as UserPassword).oldPassword,
            })
              .then(() => {
                requestAnimationFrame(() => (form.reset()))
                this.notifySuccess('Пароль обновлен')
                this.handleClose()
              })
              .catch(this.notifyError) :
            ProfileModule.savePassword({
              email: (this.form as UserSetPassword).email,
              password: (this.form as UserPassword).newPassword,
              passwordConfirmation: (this.form as UserPassword).newPasswordConfirmation,
            })
              .then(() => {
                requestAnimationFrame(() => (form.reset()))
                this.notifySuccess('Пароль создан')
                this.handleClose()
              })
              .catch(this.notifyError)
        } else {
          this.notifyError('Проверьте введенные данные')
        }
      })
  }

  private handleClose () {
    this.$emit('close')
    requestAnimationFrame(() => ((this.$refs.form as any).reset()))
    this.form = {
      email: undefined,
      newPassword: '',
      newPasswordConfirmation: '',
      oldPassword: '',
    }
  }
}
