



























import { Component, Prop, Vue } from 'vue-property-decorator'

// components
import Dialog from '@/components/modals/Dialog.vue'
import ModalWrapper from '@/components/modals/ModalWrapper.vue'

@Component({
  components: {
    Dialog,
    ModalWrapper,
  },
})
export default class AttachVKProblemModal extends Vue {
  @Prop({ default: false })
  private visible!: boolean

  private handleClose() {
    this.$emit('update:visible', false)
  }
}
